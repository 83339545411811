.text-about {
  width: 875px;
  text-align: center;
  margin-top: 127px;
  margin-bottom: 80px;
  font-size: 20px;
  line-height: 28px;
}

.store-icon {
  height: 52;
  margin-right: 24px;
}

.text-career {
  margin-top: 196px;
  font-weight: 600;
  font-size: 65px;
  line-height: 100px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.career-item {
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  ul {
    padding-inline-start: 30px;
    list-style-type: disc;
    color: #5f5f5f;
  }
}

.career-icon {
  width: 56px;
  height: 56px;
}

.career-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 12px;
}

.btn-apply {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: #15c8a0;
  width: 128px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fffefd;
  margin-left: 90px;
}

a {
  text-decoration: none !important;
}

.left-icon {
  width: 80px;
}

.store-cont {
  display: flex;
  justify-content: center;
}

.career-item-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@media (max-width: 700px) {
  .career-item {
    font-size: 14px;
    line-height: 22px;
  }
  .store-icon {
    margin: 10px 0;
  }
  .store-cont {
    display: block;
    a {
      display: flex;
      justify-content: center;
    }
  }
  .text-about {
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .text-career {
    font-size: 38px;
    line-height: 48px;
  }
  .left-icon {
    display: none;
  }
  .career-item-card {
    display: block;
  }
  .career-title {
    text-align: center;
  }
  .btn-apply {
    margin-top: 40px;
  }
}
