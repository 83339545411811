body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

@media (min-width: 1230px) {
  .container {
    max-width: 1230px;
  }
}

.header {
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  height: 120px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-top: 100px;
  font-feature-settings: "liga" off;
  box-shadow: 0px 20px 50px #0000001a;
}

.logo {
  height: 80px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hn-love {
  display: flex;
}

@media (max-width: 700px) {
  .logo {
    height: 40px;
  }
  .footer-content {
    display: block;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
  }
  .hn-love {
    display: block;
    margin-top: 8px;
  }
}
